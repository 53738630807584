import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Forms/ContactUsForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/TargetsNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Misc/GameRoomsDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Sections/NewsAndEventsSection.tsx");
