"use client"

import { useTranslations } from "next-intl";
import { GameRoom } from "../../datastore/game-room/game-room.model";
import Dropdown from "./Dropdown";

interface GameRoomsDropdownProps {
  gameRooms: GameRoom[];
}

function GameRoomsDropdown({ gameRooms }: GameRoomsDropdownProps) {
  const t = useTranslations();

  return (<Dropdown id="idfk" text={t("labels.our-game-rooms")}
    linkClass="min-w-full"
    buttonClass="w-full mx-0" links={gameRooms.map(gr => ({
      text: gr.name,
      img: gr.company === "noapel" ? "/logo.png" : "/assets/logo-admajora.png",
      link: `/game-rooms#game-room-${gr.id}`
    }))}>

  </Dropdown>);
}

export default GameRoomsDropdown;